<template>
  <div>
    <div id="in-exclusion-area">
      <div id="fix-head-area">
        <div class="area-title">Criteria of Inclusion & Exclusion</div>
        <div id="adder-container">
          <div class="d-inline-block event-adder" id="inclusion-adder" @click="addInclusionEvent">+ INCLUSION</div>
          <div class="d-inline-block event-adder" id="exclusion-adder" @click="addExclusionEvent">+ EXCLUSION</div>
        </div>
      </div>
      <div id="inclusion-event-area" v-if="inclusionLength > 0">
        <div class="inclusion-block" v-for="(event, key) in event.inclusionList" :key="key">
          <div v-if="inclusionLength > 1 && key !== 0">
            <div class="AND-word" style="margin-left: 20px">AND</div>
            <div class="seperate-line"></div>
          </div>
          <div class="area-title">
            <span>Inclusion</span>
            <span>
              <b-img @click="deleteInclusionEvent(key)" class="remove-icon" src="@/assets/images/icon_remove.svg" />
            </span>
          </div>
          <div class="event-block">
            <EventContainer
              id="event-block-1"
              :index="index"
              :eventType="EventType.INCLUSION"
              :event="event"
              :path="path.concat(['inclusionList', key])"
            />
          </div>
        </div>
      </div>
      <div v-if="inclusionLength > 0 && exclusionLength > 0">
        <div class="AND-word" style="margin-left: 20px">AND</div>
        <div class="seperate-line"></div>
      </div>
      <div id="exclusion-event-area">
        <div class="exclusion-block" v-for="(event, key) in event.exclusionList" :key="key">
          <div v-if="exclusionLength > 1 && key !== 0">
            <div class="AND-word" style="margin-left: 20px">AND</div>
            <div class="seperate-line"></div>
          </div>
          <div class="area-title">
            <span>Exclusion</span>
            <span>
              <b-img @click="deleteExclusionEvent(key)" class="remove-icon" src="@/assets/images/icon_remove.svg" />
            </span>
          </div>
          <div class="event-block">
            <EventContainer
              id="event-block-1"
              :index="index"
              :eventType="EventType.EXCLUSION"
              :event="event"
              :path="path.concat(['exclusionList', key])"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from '@/store';
import { EventType } from '@/utils/conditions/core/Interface';
import { defaultInclusionEvent, defaultExclusionEvent } from '@/utils/advance/initState';
import { AdvanceUpdateMixin, AdvanceSearchMixin, ClickMixin } from '@/mixins';
import { cloneDeep } from 'lodash';

export default {
  name: 'InExclusionContainer',
  components: {
    EventContainer: () => import('@/components/advanceSearch/EventContainer.vue'),
  },
  mixins: [AdvanceUpdateMixin, AdvanceSearchMixin, ClickMixin],
  computed: {
    ...mapState('advancePortal', ['SearchStateObject']),
    inclusionLength() {
      return this.event.inclusionList.length;
    },
    exclusionLength() {
      return this.event.exclusionList.length;
    },
  },
  data() {
    return {
      EventType,
    };
  },
  props: {
    index: {
      type: Number,
      default: 1,
    },
    path: {
      type: Array,
      default: () => [],
    },
    event: {
      type: Object,
      default: null,
    },
  },
  methods: {
    addInclusionEvent() {
      const event = cloneDeep(this.event);
      event.inclusionList.push({
        ...cloneDeep(defaultInclusionEvent),
      });
      this.updateEventWithPath(event, this.path);
      this.advanceTrackClick('addInclusionEvent', {
        path: this.path,
        event,
      });
    },

    addExclusionEvent() {
      const event = cloneDeep(this.event);
      event.exclusionList.push({
        ...cloneDeep(defaultExclusionEvent),
      });
      this.updateEventWithPath(event, this.path);
      this.advanceTrackClick('addExclusionEvent', {
        path: this.path,
        event,
      });
    },

    deleteInclusionEvent(index) {
      const event = cloneDeep(this.event);
      event.inclusionList.splice(index, 1);
      this.updateEventWithPath(event, this.path);
      this.advanceTrackClick('deleteInclusionEvent', {
        path: this.path,
        event,
      });
    },

    deleteExclusionEvent(index) {
      const event = cloneDeep(this.event);
      event.exclusionList.splice(index, 1);
      this.updateEventWithPath(event, this.path);
      this.advanceTrackClick('deleteExclusionEvent', {
        path: this.path,
        event,
      });
    },
  },
};
</script>
<style scoped>
#in-exclusion-area {
  background-color: rgba(237, 245, 251, 0.5);
  margin: 10px 20px 0px 0px;
}

.event-adder {
  padding: 5px 15px;
  background: #4a94d2;
  border-radius: 53px;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
}

.margin-20 {
  margin-top: 20px;
  margin-left: 20px;
}

#inclusion-event-area {
  margin: 0px 20px;
}

#exclusion-event-area {
  margin: 0px 20px;
}

#adder-container {
  margin-top: 10px;
  margin-left: 20px;
  padding-bottom: 20px;
}

#exclusion-adder {
  margin-left: 10px;
}

.area-title {
  font-size: 18px;
  font-weight: 700;
  color: #0f4895;
  padding-top: 10px;
  padding-left: 20px;
}

.event-block {
  padding: 0px 20px 20px;
}

.AND-word {
  width: 5%;
  display: inline-block;
  color: #828282;
}

.seperate-line {
  position: relative;
  bottom: 5px;
  width: 90%;
  display: inline-block;
  border-top: 1px solid #d6d9e1;
}
.icon-close-red {
  margin-left: 5px;
  font-size: 12px;
}
.remove-icon {
  height: 20px;
  cursor: pointer;
  margin-left: 5px;
  position: relative;
  bottom: 2px;
}
</style>
